.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.answerTable {
  margin: auto;
}

html {
  width: 100%;
  height: 100%;
}

.answerCell {
  cursor: pointer;
  background-color: #f0f0f0;
  width: 25%;
  padding: 15pt;
  border-spacing: 5pt;
}

.answerNumber {
  font-size: small;
}

.answerText {
  font-size: xx-large;
}

.incorrectMessage {
  padding-top: 5pt;
  font-size: 24pt;
  color: red;
}

.correctMessage {
  padding-top: 5pt;
  font-size: 24pt;
  color: green;
}

.bigletter {
  font-size: 140px;
}

.about {
  font-size: small;
  color: #a0a0a0;
  position: absolute;
  float: right;
  text-align: right;
  bottom: 10pt;
  right: 10pt;
}

.hint {
  font-size: small;
  color: #a0a0a0;
  position: absolute;
  float: right;
  text-align: right;
  bottom: 10pt;
  left: 10pt;
}